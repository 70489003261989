import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/core/user.service';
import { WindowRefService } from 'app/shared/core/window-ref.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(
    private userService: UserService,
    private windowRef: WindowRefService,
  ) {}

  async ngOnInit() {
    // The purpose of this component is to clear storage, then redirect user immediately to /explore
      // immediately to /explore
    await this.userService.logoutClearToken();
    this.windowRef.nativeWindow.location.pathname = "/explore";
  }
}
