import { Injectable } from "@angular/core";

import { Subject } from "rxjs";

import { LocalStorageService } from "ngx-webstorage";

import { ApiGetService } from "./apiget.service";
import { WindowRefService } from "./window-ref.service";

import { User } from "../user.type";

@Injectable()
export class UserService {
  private userSubject: Subject<User> = new Subject<User>();
  user$ = this.userSubject.asObservable();
  user: User;

  constructor(
    private apiGetService: ApiGetService,
    private localStorageService: LocalStorageService,
    private windowRef: WindowRefService,
  ) {}

  login(
    token: string = "",
    ssoToken: string = "",
    recursed = false
  ): Promise<boolean> {
    if (token && ssoToken) {
      this.localStorageService.store("ssoToken", ssoToken);
      this.localStorageService.store("token", token);
    } else if (this.user) {
      return Promise.resolve(true);
    }

    return this.apiGetService
      .get("current-user/")
      .then((user: User) => {
        if (user) {
          this.userSubject.next(user);
          this.user = user;
          return true;
        } else {
          return false;
        }
      })
      .catch((reason: any) => {
        if (reason && reason.status === 403) {
          this.localStorageService.clear();
          this.windowRef.nativeWindow.localStorage.clear();
          if (!recursed) {
            return this.login(token, ssoToken, true);
          }
          return false;
        }
        throw reason;
      });
  }

  logoutInitClearAuthSession () {
    /* 
      Logout flow is as follows (in order) 
    */

    // logoutAuth (clears auth0 session by having client visit OIDC logout endpoint)
    // logoutClearToken (clears backend DB tokens)
    // logoutClearStorage (clears client localStorage)
    // clear reactiveJS user subject 
    // redirect to /explore
    this.apiGetService
      .get("logout/")
      .then((res: any) => {
        this.windowRef.nativeWindow.location = res.logout_url;
      })
      .catch((err) => {
        this.windowRef.nativeWindow.location = '/logout'
      });
  }

  async logoutClearToken () {
    await this.logoutClearDBToken()
    this.logoutClearStorage();
    this.userSubject.next(null);
    this.user = null;
  }

  logoutClearDBToken() {
    return new Promise ((resolve, reject) => {
      this.apiGetService
        .get("logout-post/")
        .then((res: any) => {
          resolve(res)
        })
        .catch((err) => {
          resolve(err) // Probably no token exists for user on backend. Proceed with localstorage and user observable
        }); 
      })
  }

  logoutClearStorage () {
    this.localStorageService.clear();
    this.windowRef.nativeWindow.localStorage.clear();
  }

  postUser(user: User): Promise<User> {
    return this.apiGetService
      .post("current-user/", user, { usePut: true })
      .then((updatedUser: User) => {
        this.userSubject.next(updatedUser);
        this.user = updatedUser;
        return updatedUser;
      });
  }

  refreshUser(): Promise<User> {
    return this.apiGetService.get("current-user/").then((updatedUser: User) => {
      this.userSubject.next(updatedUser);
      this.user = updatedUser;
      return updatedUser;
    });
  }
}
