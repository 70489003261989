import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ShareTokenCreateComponent } from "./share-token-create/share-token-create.component";

import { AssessorAuthorizationGuard } from "./shared/authorization/assessor-authorization.guard";
import { IssuerAuthorizationGuard } from "./shared/authorization/issuer-authorization.guard";
import { PublicGuard } from "./public.guard";
import { SiteGuard } from "./site.guard";

import { RouterLinkStubDirective } from "./router-stubs";
import { AuthenticationGuard } from "./authentication.guard";
import { NotFoundComponent } from "./shared/not-found/not-found.component";
import { LinksDemoComponent } from "./links-demo/links-demo.component";
import { StripeTestComponent } from "./stripe-test.component";
import { LoadingIndicatorDemoComponent } from "./shared/loading-overlay/loading-indicator-demo/loading-indicator-demo.component";

import { HelpdeskRedirectGuard } from "./help-desk-redirect.guard";
import { LogoutComponent } from "./logout/logout.component";

const routes: Routes = [
  {
    path: "share/:id",
    component: ShareTokenCreateComponent,
    outlet: "popup",
    canActivate: [PublicGuard],
  },

  {
    path: "site",
    loadChildren: () =>
      import("app/no-whitelabel/no-whitelabel.module").then(
        (m) => m.NoWhitelabelModule
      ),
  },
  /* This guard only serves to ensure a Site check when the
   * application loads (pre-navigating) */
  {
    path: "",
    canActivate: [SiteGuard],
    children: [
      {
        path: "assess",
        loadChildren: () =>
          import("app/assess-page/assess-page.module").then(
            (m) => m.AssessPageModule
          ),
        canLoad: [AssessorAuthorizationGuard],
        canActivate: [AuthenticationGuard],
      },
      {
        path: "review",
        loadChildren: () =>
          import("app/review-page/review-page.module").then(
            (m) => m.ReviewPageModule
          ),
        canLoad: [IssuerAuthorizationGuard],
        canActivate: [AuthenticationGuard],
      },
      {
        path: "manage",
        loadChildren: () =>
          import("app/manage-page/manage-page.module").then(
            (m) => m.ManagePageModule
          ),
        canLoad: [IssuerAuthorizationGuard],
        canActivate: [AuthenticationGuard],
      },
      {
        path: "contact",
        canActivate: [HelpdeskRedirectGuard],

        // We need a component here because we cannot define the route otherwise
        component: NotFoundComponent
      },
      {
        path: "faqs",
        loadChildren: () =>
          import("app/faqs/faqs.module").then((m) => m.FaqsModule),
      },
    ],
  },

  {
    path: "file-viewer",
    loadChildren: () =>
      import("app/shared/file-uploader/app.module").then((m) => m.AppModule),
  },
  {
    path: "links-demo",
    component: LinksDemoComponent,
  },
  {
    path: "stripe-demo",
    component: StripeTestComponent,
  },
  {
    path: "loading-demo",
    component: LoadingIndicatorDemoComponent,
  },
  {
    path: "logout",
    canActivate: [AuthenticationGuard],
    component: LogoutComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
    canActivate: [PublicGuard],
    data: { title: "404 Not Found" },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      relativeLinkResolution: "legacy",
    }),
  ],
  declarations: [RouterLinkStubDirective],
  providers: [AuthenticationGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
