import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxWebstorageModule } from "ngx-webstorage";
import { ToastNoAnimationModule } from "ngx-toastr";
import { ToastMessageComponent } from "./shared/toast-messages/toast-message/toast-message.component";

import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./shared/core/core.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";

import { HomepageModule } from "./homepage/homepage.module";
import { BrowsePageModule } from "./browse-page/browse-page.module";
import { CredentialDetailModule } from "./credential-detail/credential-detail.module";
import { SubmissionCreateModule } from "./submission-create/submission-create.module";
import { SubmissionDetailModule } from "./submission-detail/submission-detail.module";
import { RecommendationCreateModule } from "./recommendation-create/recommendation-create.module";
import { DecisionCreateModule } from "./decision-create/decision-create.module";
import { AwardedCredentialDetailModule } from "./awarded-credential-detail/awarded-credential-detail.module";
import { ProgressPageModule } from "./progress-page/progress-page.module";
import { ProfileModule } from "./profile/profile.module";
import { FaqsModule } from "./faqs/faqs.module";

import { TryLoginGuard } from "./try-login.guard";
import { ShareTokenCreateModule } from "./share-token-create/share-token-create.module";
import { ShareTokenCreateRoutingModule } from "./share-token-create/share-token-create-routing.module";
import { PublicGuard } from "./public.guard";

import { LinksDemoComponent } from "./links-demo/links-demo.component";
import { StripeTestComponent } from "./stripe-test.component";
import { NoWhitelabelModule } from "./no-whitelabel/no-whitelabel.module";
import { EditCredentialService } from "./shared/edit-credential/edit-credential.service";
import { NgxFocusTrapModule } from "ngx-focus-trap";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { environment } from "../environments/environment";
import { AirbrakeErrorHandler } from "./airbrake-error-handler";
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [AppComponent, LinksDemoComponent, StripeTestComponent, LogoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxFocusTrapModule,
    NgxDatatableModule,
    NgxWebstorageModule.forRoot({
      prefix: "dp",
    }),

    ToastNoAnimationModule.forRoot({
      timeOut: 5000,
      toastComponent: ToastMessageComponent,
      closeButton: true,
      toastClass: "",
      iconClasses: {
        error: "message-error",
        info: "message-info",
        success: "message-confirm",
        warning: "message-warning",
      },
    }),

    HomepageModule,
    BrowsePageModule,
    CredentialDetailModule,
    SubmissionCreateModule,
    SubmissionDetailModule,
    RecommendationCreateModule,
    DecisionCreateModule,
    AwardedCredentialDetailModule,
    ProgressPageModule,
    ProfileModule,
    FaqsModule,
    ShareTokenCreateModule,
    ShareTokenCreateRoutingModule,
    AppRoutingModule,
    NoWhitelabelModule,
    ...(!environment.production
      ? []
      : [
          NgxGoogleAnalyticsModule.forRoot("G-CDMVVHFZG8"),
          NgxGoogleAnalyticsRouterModule.forRoot(),
        ]),
  ],
  providers: [
    TryLoginGuard,
    PublicGuard,
    EditCredentialService,
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
